<template>
  <div class="page-box">
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="params" @submit.native.prevent>
        <a-form-model-item label="商品名称" prop="productName">
          <a-input v-model="params.productName" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div class="btn-box">
      <a-button type="primary" @click="$router.push('/activity/exchange/add')" v-pms="'add'">新建兑换券</a-button>
    </div>
    <base-table bordered :columns="columns" :dataSource="tableData" :pagination="pagination" :rowKey="record => record.orderVoucherCreateId" :loading="tableLoading" @change="handleTableChange">
      <template slot="name" slot-scope="text,record">
        <a-button type="link" @click="goDetail(record)">{{text}}</a-button>
      </template>
      <template slot="productName" slot-scope="text,record">
        <a-popover title placement="topLeft">
          <template slot="content">
            <p>{{text}}</p>
          </template>
          <template>
            <div style="display: flex; align-items: center;">
              <base-img :src="record.productSkuPic||record.mainPicPath" style="flex-shrink:0;margin-right:8px;" width="60" height="60"></base-img>
              <div>
                <a-button type="link" :title="text" class="el" style="padding: 0;max-width:200px" @click="$router.push(
                  `/goods/goods_detail/${record.productId}`
                )">{{text}}</a-button>
                <div v-if='record.productSkuName' style='color:#666;'>规格：{{record.productSkuName}}</div>
              </div>
            </div>
          </template>
        </a-popover>
      </template>
    </base-table>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted } from '@vue/composition-api'
import { activity } from '@/api'
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import moment from 'moment'
const columns = [
  {
    title: '创建日期',
    dataIndex: 'createTime',
    customRender: (x) => moment(x).format('YYYY-MM-DD'),
  },
  {
    title: '名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: '有效期',
    dataIndex: 'validEndTime',
    customRender: (x) => moment(x).format('YYYY-MM-DD'),
  },
  {
    title: '张数',
    dataIndex: 'voucherNum',
  },
  {
    title: '关联商品',
    dataIndex: 'productName',
    scopedSlots: { customRender: 'productName' },
  },
]
export default {
  name: 'PageExchange',
  setup (props, { root }) {
    const state = reactive({
      params: {
        shopId: getSession(SHOPID),
        productName: '',
      },
      formRef: null,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      tableData: [],
      tableLoading: false,
    })
    const callback = () => {
      getTableData()
    }
    onMounted(() => {
      getTableData()
      root.$bus.$on('PageExchange:refresh', callback)
    })
    onUnmounted(() => {
      root.$bus.$off('PageExchange:refresh', callback)
    })
    async function getTableData () {
      state.tableLoading = true
      let { code, data, page, msg } = await activity.getExchangeActivityPage({
        current: state.pagination.current,
        size: state.pagination.pageSize,
        ...state.params,
      })
      state.tableLoading = false
      if (code === '00000') {
        state.tableData = data
        state.pagination.current = page.pageNo
        state.pagination.pageSize = page.pageSize
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function handleTableChange ({ current }) {
      state.pagination.current = current
      getTableData()
    }

    function handleSearch () {
      state.pagination.current = 1
      getTableData()
    }

    function handleReset () {
      state.formRef.resetFields()
      handleSearch()
    }

    function goDetail (p) {
      root.$router.push(
        `/activity/exchange/detail/${encodeURIComponent(
          JSON.stringify({
            name: p.name,
            validEndTime: p.validEndTime,
            productName: p.productName,
            productSkuName: p.productSkuName,
            productId: p.productId,
            voucherNum: p.voucherNum,
            remark: p.voucherNum,
            mainPicPath: p.mainPicPath,
            productSkuPic: p.productSkuPic,
          })
        )}`
      )
    }

    return {
      moment,
      columns,
      ...toRefs(state),
      handleReset,
      handleSearch,
      handleTableChange,
      getTableData,
      goDetail,
    }
  },
}
</script>

<style lang="less" scoped>
.dialog-foot {
  padding-top: 18px;
  margin-top: 18px;
  border-top: 1px solid #ddd;
}
::v-deep .ant-form-item {
  margin-bottom: 8px;
}
.role-name-box {
  display: flex;
  align-items: center;
  .official-flag {
    background: @main-color;
    color: #fff;
    padding: 4px;
    font-size: 12px;
    line-height: 12px;
    border-radius: 2px;
    margin-left: 6px;
    font-weight: bolder;
    flex-shrink: 0;
  }
}
.tips {
  font-size: 12px;
  color: #666;
  line-height: 24px;
}
.btn-box {
  margin-bottom: 18px;
  ::v-deep .ant-btn {
    margin-right: 12px;
    &:last-child {
      margin: 0;
    }
  }
}
</style>
